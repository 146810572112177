import autoArchivoInvestigacionDisciplinaria from "./autoArchivoInvestigacionDisciplinaria"
import autoInicioIndagacionPrevia from "./autoInicioIndagacionPrevia"
import inicioInvestigacionDisciplinaria from "./inicioInvestigacionDisciplinaria"
import noRecursoApelacion from "./noRecursoApelacion"
import SiProcedeRecursoApelacion from "./siProcedeRecursoApelacion"
import noContinueInvestigacion from "./noContinueInvestigacion"
import siContinueInvestigacion from "./siContinueInvestigacion"
import noApoderado from "./noApoderado"
import siApoderado from "./siApoderado"
import noProcedeConfesion from "./noProcedeConfesion"
import siArchivarInvesticacionDisciplinaria from "./siArchivarInvesticacionDisciplinaria"
import autoInhibitorio from "./autoInhibitorio"

const index = {
    autoInicioIndagacionPrevia: autoInicioIndagacionPrevia,
    autoInhibitorio: autoInhibitorio,
    inicioInvestigacionDisciplinaria: inicioInvestigacionDisciplinaria,
    autoArchivoInvestigacionDisciplinaria: autoArchivoInvestigacionDisciplinaria,
    noRecursoApelacion: noRecursoApelacion,
    siProcendeRecursoApelacion: SiProcedeRecursoApelacion,
    noContinueInvestigacion: noContinueInvestigacion,
    siContinueInvestigacion: siContinueInvestigacion,
    noApoderado: noApoderado,
    siApoderado: siApoderado,
    noProcedeConfesion: noProcedeConfesion,
    siArchivarInvesticacionDisciplinaria: siArchivarInvesticacionDisciplinaria
}

export default index