import { useState } from 'react';
import { Backdrop, Box, Modal, Fade, Button, Typography } from '@mui/material';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '95%',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
  padding: '20px',
};

/**
 * @description Componente que muestra un modal con un título y un componente.
 * @param {Object} title - Título del modal.
 * @param {Object} Component - Componente a mostrar en el modal.
 * @param {Object} aux - Variable auxiliar.
 */
export default function TransitionsModal({ title, Component, aux }) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button onClick={handleOpen}>Ver mapa</Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography variant="h6" component="h2" sx={{ marginBottom: '1rem', fontWeight: 'bold' }}>
              {title}
            </Typography>
            <Component aux={aux} />
            {/* btn de cerrar */}
            <Button variant="outlined" onClick={handleClose}>
              Cerrar
            </Button>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
