export * from './ModalUpdateRequest';
export * from './ModalCreateRequest';
export * from './RequestAssigned';
export * from './AllRequest';
export * from './UploadFileComponent';
export * from './ShowFiles';
export * from './modals/modalAutoInhibitorio';
export * from './modals/modalMemorandoOficioComunicacionQuejosoInhibitorio';
export * from './modals/modalRecepcionRecurso';
export * from './modals/documentToEdit';
export * from './modals/modalViewer';
export * from './modals/ModalComment';

export const HASH_CAN_REQUEST = {
  'Ventanilla Unica': true,
  'Secretaria Comun Juzgamiento': true,
};

export const positionsArray = [
  { id: 1, top: '-0.5%', left: '6%' },
  { id: 2, top: '6%', left: '6%' },
  { id: 3, top: '35%', left: '6%' },
  {
    id: 4,
    top: '35%',
    left: '10.5%',
  },
  {
    id: 5,
    top: '13%',
    left: '10.5%',
  },
  {
    id: 6,
    top: '65%',
    left: '11.5%',
  },
  {
    id: 7,
    top: '65%',
    left: '13.5%',
  },
  {
    id: 8,
    top: '28%',
    left: '8.6%',
  },
  {
    id: 9,
    top: '7.8%',
    left: '14.85%',
  },
  {
    id: 10,
    top: '65%',
    left: '14.85%',
  },
  {
    id: 11,
    top: '28%',
    left: '17.7%',
  },
  {
    id: 12,
    top: '7.8%',
    left: '17.7%',
  },
  {
    id: 13,
    top: '65%',
    left: '18.85%',
  },
  { id: 14, top: '28%', left: '23.3%' },
  { id: 15, top: '8%', left: '23.3%' },
  { id: 16, top: '63%', left: '24%' },
  { id: 17, top: '68.4%', left: '23.5%' },
  { id: 18, top: '68.4%', left: '26.5%' },
  { id: 19, top: '39.8%', left: '45.2%' },
  { id: 20, top: '7%', left: '45.2%' },
  { id: 21, top: '65%', left: '47.3%' },
  { id: 22, top: '44.5%', left: '57.9%' },
  { id: 23, top: '7%', left: '57.9%' },
  { id: 24, top: '31%', left: '60.4%' },
  { id: 25, top: '7%', left: '60.4%' },
  { id: 26, top: '65%', left: '61.5%' },
  { id: 27, top: '70%', left: '61.5%' },
  { id: 28, top: '41%', left: '63%' },
  { id: 29, top: '7%', left: '62.8%' },
  { id: 30, top: '64.5%', left: '67.1%' },
  { id: 31, top: '64.5%', left: '71.5%' },
  { id: 32, top: '41%', left: '71.4%' },
  { id: 33, top: '-0.5%', left: '25%' },
  { id: 34, top: '68.4%', left: '28.6%' },
  { id: 35, top: '71.8%', left: '35.6%' },
  { id: 36, top: '67.8%', left: '31.6%' },
  { id: 37, top: '67.8%', left: '33%' },
  { id: 38, top: '7.8%', left: '33%' },
  { id: 39, top: '40%', left: '48.6%' },
  { id: 40, top: '34.6%', left: '49.6%' },
  { id: 41, top: '7.6%', left: '49.6%' },
  { id: 42, top: '34.6%', left: '51.2%' },
  { id: 43, top: '7.6%', left: '53.3%' },
  { id: 44, top: '40%', left: '55%' },
  { id: 45, top: '46%', left: '55%' },
  { id: 46, top: '51.4%', left: '64.5%' },
  { id: 47, top: '12.4%', left: '64.5%' },
  { id: 48, top: '70%', left: '65.5%' },
  { id: 49, top: '70%', left: '67%' },
  { id: 50, top: '70%', left: '70.5%' },
  { id: 51, top: '70%', left: '72.5%' },
  { id: 52, top: '-0.5%', left: '69.5%' },
  { id: 53, top: '67%', left: '74%' },
  { id: 54, top: '72%', left: '76%' },
  { id: 55, top: '67%', left: '77.7%' },
  { id: 56, top: '67%', left: '79%' },
  { id: 57, top: '11%', left: '79.1%' },
  { id: 58, top: '38.8%', left: '84.8%' },
  { id: 59, top: '11%', left: '84.8%' },
  { id: 60, top: '67%', left: '85.6%' },
  { id: 61, top: '67%', left: '87.3%' },
];
