import { useState, useEffect } from 'react';
// assets
import IMGH from '../../../assets/images/testMapPreviewv2.png';
// icons
import { Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ZoomIn, ZoomOut, FitScreen } from '@mui/icons-material';
// styles
import './FlowDiagram.css';
// variables
import { positionsArray } from './index';

/**
 * @description Componente que muestra un diagrama de flujo para saber su posición acutal. Con controles para hacer zoom, ajustar a pantalla.
 * @param {Object} aux - Variable auxiliar, en este caso el objeto con la posición actual del indicador en el pos 0.
 */
interface FlowDiagramProps {
  aux: any[]; // Replace 'any[]' with the appropriate type for the 'aux' prop
}

const FlowDiagram = ({ aux }: FlowDiagramProps) => {
  const [zoom, setZoom] = useState(1);
  // Posición inicial del indicador
  const [position, setPosition] = useState({ top: '13.9%', left: '10.7%' });
  // media query
  const theme = useTheme();
  const isMdUp = useMediaQuery((theme: any) => theme.breakpoints.up('md'));

  // Estilo del indicador
  const indicatorStyle = 'style4';

  useEffect(() => {
    if (aux) {
      try {
        const CURRENT_POSITION = aux[0];
        const CURRENT_POSITION_INDEX = positionsArray.find((position) => position.id == CURRENT_POSITION);
        let { top, left } = CURRENT_POSITION_INDEX || { top: '13.9%', left: '10.7%' };
        setPosition({ top, left });
      } catch (e) {
        // Si no se encuentra el índice, se asigna la posición por defecto
        setPosition({ top: '13.9%', left: '10.7%' });
        console.log('Index not found: ', e);
      }
    } else {
      return;
    }
  }, []);

  const handleZoomIn = () => {
    setZoom((prevZoom) => Math.min(prevZoom + 0.1, 2));
  };

  const handleZoomOut = () => {
    setZoom((prevZoom) => (prevZoom > 1 ? Math.max(prevZoom - 0.1, 0.5) : 1));
  };

  const handleFitScreen = () => setZoom(1);

  return (
    <>
      <div className="diagram-container">
        <div className="diagram-wrapper flex justify-center">
          <div className="diagram-content" style={{ transform: `scale(${zoom})` }}>
            <img src={IMGH} alt="Diagrama de flujo" className="diagram-image" />
            <div className={`indicator ${indicatorStyle}`} style={{ top: position.top, left: position.left }}></div>
          </div>
        </div>
      </div>
      <div className="controls">
        <section className="flex flex-row justify-center items-center">
          <Button
            sx={{
              margin: '0px 1rem 0px 1rem',
              [theme.breakpoints.down('md')]: {
                flexDirection: 'row',
                padding: 0,
              },
            }}
            variant="contained"
            onClick={handleZoomIn}
            endIcon={isMdUp ? <ZoomIn /> : null}
          >
            {isMdUp ? 'Acercar' : <ZoomIn />}
          </Button>
          <Button
            sx={{
              margin: '0px 1rem 0px 1rem',
              [theme.breakpoints.down('md')]: {
                flexDirection: 'row',
                padding: 0,
              },
            }}
            variant="contained"
            onClick={handleZoomOut}
            endIcon={isMdUp ? <ZoomOut /> : null}
          >
            {isMdUp ? 'Alejar' : <ZoomOut />}
          </Button>
          <Button
            sx={{
              margin: '0px 1rem 0px 1rem',
              [theme.breakpoints.down('md')]: {
                flexDirection: 'row',
                padding: 0,
              },
            }}
            variant="contained"
            onClick={handleFitScreen}
            endIcon={isMdUp ? <FitScreen /> : null}
          >
            {isMdUp ? 'Ajustar a la pantalla' : <FitScreen />}
          </Button>
        </section>
      </div>
    </>
  );
};

export default FlowDiagram;
